<template>
    <fieldset>

        <div class="form-card text-left">

            <b-row>

                <b-form-group class="col-md-6" label="Source: *" label-for="source">
                    <multiselect v-model="form.source" :value="form.source" placeholder="Select the source" label="name"
                        track-by="code" :options="form.sourceOptions" :multiple="false" :taggable="false">

                        <template slot="option" slot-scope="props">
                            {{ props.option.name }}
                        </template>

                        <template slot="singleLabel" slot-scope="props">
                            {{ props.option.name }}
                        </template>

                    </multiselect>
                    <small v-if="errors.source" ref="errors_source" class="text-danger d-none">{{ errors.source }}</small>
                </b-form-group>

                <b-form-group class="col-md-6" label="Automation Name: *" label-for="name">
                    <b-form-input type="text" placeholder="Automation Name" v-model="form.name"></b-form-input>
                    <small v-if="errors.name" ref="errors_name" class="text-danger d-none">
                        {{ errors.name }}
                    </small>
                </b-form-group>

            </b-row>

            <!-- Filters Events -->
            <fieldset class="bg-white" :variant="'secondary'">

                <legend>
                    <label class="small" for="filters-button">Automation Options</label>

                    <small v-if="errors.triggers" ref="errors_triggers" class="text-danger small d-none">
                        {{ errors.triggers }}
                    </small>
                </legend>

                <b-row>
                    <b-col md="6" class="px-1" v-if="(isAdmin || hasOption(current_service,'validation')) && false">
                      <b-form-group class="col-md-12 my-auto my-2" label="" label-for="">
                        <div class="row">
                          <div class="col d-flex justify-content-between">
                            <label for="">  Validation</label>
                            <b-form-checkbox
                                v-model="triggers.cleaning"
                                name="check-button"
                                button-variant="primary"
                                size="md"
                                switch
                                @input=""></b-form-checkbox>
                          </div>
                        </div>
                        </b-form-group>

                        <b-form-group v-show="triggers.cleaning" class="col-md-12" label="" label-for="record-state">
                            <multiselect v-model="form.recordState" :value="form.recordState" placeholder="Cleaning states"
                                label="name" track-by="code" :options="form.cleanOptions" :multiple="true"
                                :taggable="false">
                            </multiselect>
                        </b-form-group>

                        <small v-if="errors.recordState" ref="errors_recordState" class="text-danger d-none">{{ errors.recordState }}</small>
                    </b-col>

                    <b-col md="6" class="px-1"  v-if="isAdmin || hasOption(current_service,'time_spent')">
                        <b-form-group class="col-md-12 my-auto my-2" label="" label-for="">
                        <div class="row">
                            <div class="col d-flex justify-content-between">

                            <label> Time Spent on Site (Time Between in minutes)</label>

                                <b-form-checkbox
                                    v-model="triggers.timeSpent"
                                    name="check-button"
                                    button-variant="primary"
                                    size="md"
                                    switch @input="initTimeSpent">

                                </b-form-checkbox>

                            </div>
                        </div>
                        </b-form-group>

                        <b-form-group v-show="triggers.timeSpent" class="col-md-12" label="" label-for="time-spent">

                            <b-row>

                                <b-col md="6" class="mb-1 px-1">
                                    
                                    <div class="input-group">
                                        <label for="time-from" class="my-auto mx-3">From</label>

                                        <b-form-input class="form-control mx-1" name="timeFromM" type="number"
                                            v-model="form.timeFromM" min="00" max="60"
                                            @change="formatterTime($event, 'timeFromM')"
                                                      style="margin: 0 1px !important;"
                                        ></b-form-input>
                                        <b-form-input class="form-control mx-1" name="timeFromS" type="number"
                                            v-model="form.timeFromS" min="00" max="60"
                                            @change="formatterTime($event, 'timeFromS')"
                                                      style="margin: 0 1px !important;"
                                        ></b-form-input>
                                    </div>
                                </b-col>


                                <b-col md="6" class="mb-1 px-1">
                                    <div class="input-group">
                                        <label for="time-to" class="my-auto mx-4">To</label>

                                        <b-form-input class="form-control mx-1" name="timeToM" type="number"
                                            v-model="form.timeToM" min="00" max="60"
                                            @change="formatterTime($event, 'timeToM')"
                                                      style="margin: 0 1px !important;"></b-form-input>
                                        <b-form-input class="form-control mx-1" name="timeToS" type="number"
                                            v-model="form.timeToS" min="00" max="60"
                                            @change="formatterTime($event, 'timeToS')"
                                                      style="margin: 0 1px !important;"></b-form-input>
                                    </div>
                                </b-col>

                            </b-row>

                        </b-form-group>

                        <small v-if="errors.timeSpent" ref="errors_timeSpent" class="text-danger d-none">{{ errors.timeSpent }}</small>
                    </b-col>

                    <b-col md="6" class="px-1" v-if="isAdmin || hasOption(current_service,'event_action')">
                      <b-form-group class="col-md-12 my-auto my-2" label="" label-for="">
                        <div class="row">
                          <div class="col d-flex justify-content-between">
                            <label>Actions</label>
                            <b-form-checkbox v-model="triggers.actions" name="check-button" button-variant="primary"
                                size="md" switch @input="">
                            </b-form-checkbox>
                          </div>
                        </div>
                        </b-form-group>
                        <b-form-group v-show="triggers.actions" class="col-md-12" label="" label-for="actions">
                            <multiselect v-model="form.action" :value="form.action" placeholder="Indicate the actions"
                                label="name" track-by="code" :options="form.actions" :multiple="false" :taggable="false">
                            </multiselect>
                        </b-form-group>

                        <small v-if="errors.actions" ref="errors_actions" class="text-danger d-none">{{ errors.actions }}</small>
                    </b-col>

                    <b-col md="6" class="px-1" v-if="isAdmin || hasOption(current_service,'recurrent')" >
                        <b-form-group class="col-md-12 my-auto my-2" label="" label-for="">
                        <div class="row">
                            <div class="col d-flex justify-content-between">
                            <label>Recurring visitors</label>
                            <b-form-checkbox
                                switch
                                v-model="form.has_recurrent"
                                name="check-recurrent"
                                button-variant="primary"
                                size="md" >  </b-form-checkbox>
                            </div>

                        </div>

                        <b-form-input v-if="form.has_recurrent" type="number" v-model="form.recurrent_interval"></b-form-input>
                        </b-form-group>

                    </b-col>
                </b-row>

            </fieldset>

        </div>

    </fieldset>
</template>

<script>
import Multiselect from "vue-multiselect"
import moment from 'moment'
import {GE_RECORD_CLEAN_STATES, SERVICES} from "@/constantes";
import {mapGetters, mapState} from "vuex";

export default {
    name: "StepSource",
    props: ['form', 'errors','isCreateAction'],
    components: {
        Multiselect
    },
    data() {
        return {
            current_service : SERVICES.LIVE_LEADS.id,
            checked: false,
            triggers: {
                cleaning: false,
                timeSpent: false,
                actions: false,
            }
        }
    },
    mounted() {
        this.showErrors()

        if (typeof this.form.triggers !== 'undefined') this.triggers = this.form.triggers;
        else {
            this.form.triggers = this.triggers;
        }

        this.applyDefaultValues();
    },
    methods: {
        showErrors() {
            _.forOwn(this.errors, (error, key) => {
                const ref = `errors_${key}`
                if (typeof this.$refs[ref] !== 'undefined') this.$refs[ref].classList.remove('d-none')
            });
        },
        resetErrors() {
            _.forOwn(this.errors, (error, key) => {
                const ref = `errors_${key}`
                this.$refs[ref].classList.add('d-none')
            });
        },
        initTimeSpent(value) {
            this.form.timeSpent = value;
        },
        isValidTime(time) {

            this.errors.timeSpent = '';

            let format = 'HH:mm:ss';
            const TIME = moment(time, format);
            const minTime = moment('00:00:00', format);
            const maxTime = moment('00:15:00', format);

            let state = TIME.isBetween(minTime, maxTime, undefined, '[]');

            // if (!state) this.errors.timeSpent = 'You must set a time between 00:00:00 & 00:15:00 minutes';
            return state;

        },
        formatterTime(value, key) {
            if (value && parseInt(value) < 10) {
                value = `0${value}`;
                this.form[key] = value
            }

            return value;
        },
      applyDefaultValues(){
            if (this.isAdmin || this.hasOption(this.current_service, 'validation')) {

                let default_selected_values = [];

                this.triggers.cleaning = false;

                // check all values and select the default ones

                GE_RECORD_CLEAN_STATES.forEach((clean_status) => {

                    if (default_selected_values.includes(clean_status.name.toLowerCase())) {

                      if(this.isCreateAction === true){

                        if(!this.form.recordState){
                          this.form.recordState = [];
                        }

                        this.form.recordState.push( clean_status);
                      }


                    }

                });
            }
      }
    },
    watch: {
        'triggers': {
            handler(newValue, oldValue) {
                this.$emit('updateTriggers', newValue)
            },
            deep: true
        },
    },
    computed : {

    ...mapGetters({
      hasOption : 'Auth/hasOption',
      isAdmin: 'Auth/isAdmin',
      isClient: 'Auth/isClient',
    }),
  },
};
</script>